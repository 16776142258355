.flexible-modal {
    position: fixed!important;
    z-index: 10!important;
    border: 1px solid #ccc!important;
    background: white!important;
    font-family: Open Sans, sans-serif!important;
    border-radius: 15px!important;
    padding-top: 40px!important;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45)!important;
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45)!important;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45)!important;
}
.flexible-modal-mask {
    position: fixed!important;
    height: 100%!important;
    background: rgba(55, 55, 55, 0.6)!important;
    top:0!important;
    left:0!important;
    right:0!important;
    bottom:0!important;
}
.flexible-modal-resizer {
    position:absolute!important;
    right:0!important;
    bottom:0!important;
    cursor:se-resize!important;
    border-bottom: solid 2px #333!important;
    border-right: solid 2px #333!important;
    border-bottom-right-radius: 15px!important;
}
.flexible-modal-drag-area{
    background: #F4F4F4!important;
    height: 35px!important;
    width: 100%!important;
    position:absolute!important;
    left:0!important;
    top:0!important;
    cursor:move!important;
    border-top-left-radius: 15px!important;
    border-top-right-radius: 15px!important;
}
